<template>
  <sign-page
    title-text="岗位等级"
    :request="request"
    table-size="large"
    :column-list="columnList"
    :form-parms-add="formParms"
    :form-parms-update="formParms"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  wageJobTitleRequest as request
} from '@/api'

export default {
  computed: {
    formParms: {
      get () {
        let data = [{
          type: 'select',
          label: '岗位类型',
          selectDatas: this.$store.getters.selectDatas['job_title_type'],
          key: 'jobTitleType',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '岗位层级',
          selectDatas: [{key: '初级', label: '初级'}, {key: '中级', label: '中级'}, {key: '高级', label: '高级'}],
          key: 'topLevel'
        },
        {
          type: 'select',
          label: '职称级别',
          selectDatas: [{key: '员级', label: '员级'}, {key: '助理级', label: '助理级'}, {key: '中级', label: '中级'}, {key: '副高级', label: '副高级'}, {key: '正高级', label: '正高级'}],
          key: 'mLevel'
        },
        {
          type: 'input',
          label: '职称等级',
          key: 'title'
        },
        {
          type: 'input',
          label: '工资标准',
          key: 'wage',
          check: {
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = this.formParms.map(v => {
          return {
            title: v.label,
            sort: true,
            field: v.key
          }
        })
        return data
      }
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
